import { aeroApiMng } from '@/plugins/axios.js';

const API_ENDPOINT = 'https://help.1caero.ru/KrishaApi';

function auth() {
  return aeroApiMng.get(`${API_ENDPOINT}/Auth.ashx`).then((res) => {
    const headers = res.headers;

    if (!('sid' in headers)) return null;

    return headers['sid'];
  });
}

function getRequestList(sid) {
  return aeroApiMng
    .get(`${API_ENDPOINT}/GetRequestList.ashx`, {
      headers: {
        sid,
      },
    })
    .then((res) => res?.data);
}

function addRequest(sid, params) {
  return aeroApiMng
    .get(`${API_ENDPOINT}/AddRequest_Android.ashx`, {
      headers: { sid },
      params,
    })
    .then((res) => res);
}

function getRequestInfo(sid, requestId) {
  return aeroApiMng
    .get(`${API_ENDPOINT}/GetRequestWithMessages.ashx`, {
      headers: { sid },
      params: { reqlist: requestId },
    })
    .then((res) => res?.data);
}

export default {
  auth,
  getRequestList,
  getRequestInfo,
  addRequest,
};
